import React, { useState } from "react";
// import IndicatorButton from "./Indicatorbutton";
import { NavLink } from "react-router-dom";
// import { Link } from 'react-scroll';

import opensea from "../assets/opensea.png";
import discord from "../assets/discord.png";
import { HashLink } from "react-router-hash-link";

const Navbar = ({
  account,
  connectMetaMask,
}) => {

  const [toggle, setToggle] = useState(false)
  const [dash, setDash] = useState("")
  

  const activeMenu = (data) => {
    setDash(data);
  }

  const closeMenu = () => {
    setToggle(false);
  }

  return (
    <header className="custom-header">
      <nav className="navbar navbar-expand-lg fixed-top  ">
        <div className="container">
          <div className="navbar-brand" href="#">
            <div className="social-icons-navabr">
              <a href="https://discord.com/channels/876330240807419934/888978095581855804" target="_blank"><img src={discord} /></a>
              <a href="https://twitter.com/happysharksnft" target="_blank"><i className='fa fa-twitter'></i></a>
              <a href="https://www.instagram.com/happysharksnft/" target="_blank" ><i className='fa fa-instagram'></i></a>
              <a href="https://opensea.io/collection/happysharks" target="_blank"><img src={opensea} /></a>
            </div>
          </div>
          <button className="navbar-toggler" type="button"
            data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
            aria-expanded="false" aria-label="Toggle navigation"
            onClick={() => setToggle(!toggle)}>
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={"collapse navbar-collapse " + (toggle == true ? ('show') : (''))} id="navbarSupportedContent">

            <ul className="navbar-nav me-auto mx-auto mb-2 mb-lg-0">
              <li className="nav-item">

                {/* linking using HashLinks */}

                < HashLink smooth to="/#about" className={"nav-link " + (dash == "about" ? ('active') : (''))} aria-current="page"
                  onClick={() => { closeMenu(); activeMenu("about"); }}>About</HashLink>

              </li>
              <li className="nav-item">

                < HashLink smooth to="/#roadmap" className={"nav-link " + (dash == "roadmap" ? ('active') : (''))} aria-current="page"
                  onClick={() => { closeMenu(); activeMenu("roadmap"); }}>Roadmap</HashLink> 

              </li>
              <li className="nav-item">
               
                < HashLink smooth to="/#faq" className={"nav-link " + (dash == "faq" ? ('active') : (''))} aria-current="page"
                  onClick={() => { closeMenu(); activeMenu("faq"); }}>faq</HashLink>

              </li>
              <li className="nav-item">
        
                <NavLink to="/ourteam" spy={true} smooth={true} offset={50} duration={500} className={"nav-link " + (dash == "team" ? ('active') : (''))} onClick={() => { closeMenu(); activeMenu("team"); }}>Team</NavLink> 

              </li>

            </ul>
            {/* <div className="conect-button">
              <IndicatorButton {...{ account, connectMetaMask }} />
            </div> */}
            <div class="conect-button">
              <a href="javascript:;" class="white-custom-button">CONNECT WALLET</a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
};
export default Navbar;

