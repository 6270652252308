import React from "react";

function Sharkteam() {

    return (
        <>
            <section className="team-section space-top-bottom" id="sharkteam">
                <div className="team-image">
                    <div className="coming-soon-content">
                        <p>- Coming Soon -</p>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Sharkteam;