import React, { useState, useEffect } from 'react';
import logo from "../assets/site-logo.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import happyshark from "../assets/HAPPY_SHARK_FRAME.png";
 import happyshark from "../assets/sharkone.png";
import team3 from "../assets/team3.jpeg";
import team4 from "../assets/team4.jpeg";
import "./Bubbles.js";

const About = ({ mint }) => {

    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);
    const [value, setValue] = useState(0);

    useEffect(() => {

        setNav1(slider1);
        setNav2(slider2);

    });

    const settingsMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav'
    };

    const settingsThumbs = {
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: false,
        centerMode: true,
        swipeToSlide: true,
        focusOnSelect: true,
        centerPadding: '0px',
    };

    const slidesData = [happyshark, team3, team4, happyshark, team3, team4];

    return (
        <>
            <div className='id-space' id="about"></div>
            <section className="about-section space-top-bottom" >
                <div className="bubbles">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-12 col-12">
                                <div className="shark-image-frame-image">

                                    {/* slick-slider begin*/}

                                    <div className="App">

                                        <div className="slider-wrapper">

                                            <Slider
                                                {...settingsMain}
                                                asNavFor={nav2}
                                                ref={slider => (setSlider1(slider))}
                                            >

                                                {slidesData.map((slide, index) =>

                                                    <div className="slick-slide" key={index}>
                                                        <figure className='thumb-img-outer'>
                                                            <img className="slick-slide-image"  alt={"slider-thumb-"+index} src={slide} />
                                                        </figure>
                                                    </div>

                                                )}

                                            </Slider>
                                            <div className="thumbnail-slider-wrap">
                                                <Slider
                                                    {...settingsThumbs}
                                                    asNavFor={nav1}
                                                    ref={slider => (setSlider2(slider))}>

                                                    {slidesData.map((slide, index) =>

                                                        <div className="slick-slide" key={index}>
                                                            <img className="slick-slide-image" alt={"slider-image-"+index} src={slide} />
                                                        </div>

                                                    )}

                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                    {/* slick-slider end */}
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-12 col-12">
                                <div className="about-content">
                                    <img className="site-logo img-fluid" src={logo} />
                                    <p>Unless you’re a bottom feeder, real sharks don’t stop swimming. So where do they all go when no one’s watching? With a Shark card, you can finally find out. </p>
                                    <p>Explore a network of sunken ships hidden in the depths. Join 5,555 apex predators in the Shark Card Shiver to access a members-only deep-sea map. Connect, collaborate, and create with sharkholders at the top of the business food chain.</p>
                                    <div className="mint-button">

                                        {/* <a href="javascript:;" className="yellow-custom-button"
                                      onClick={() => {
                                        mint(value);
                                      }}
                                    >mint a shark</a> */}
                                        <a href="javascript:;" className="yellow-custom-button">mint a shark</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default About;
