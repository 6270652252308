import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const option = {
    stagePadding: 210,
    loop: true,
    margin: 60,
    nav: false,
    dots: true,
    items: 1,
    center: true, responsive: {
        0: {
            items: 1,
            stagePadding: 0,
        },
        1000: {
            items: 1,
        }
    }
}

function Roadmap() {

    const slidesData = [

        {
            id: 1,
            percentage: "0-9%",
            body: 'Marketing + website relaunch + team recruiting.'
        },
        {
            id: 2,
            percentage: "10%",
            body: 'Relaunch minting of HappySharks.'
        },
        {
            id: 3,
            percentage: "20%",
            body: 'Investors are paid back. Begin coding for Sea Treasures Utility (See Discord Shiver).'
        },
        {
            id: 4,
            percentage: "30%",
            body: '$10,000 donation or reinvestment determined by a Shiver vote.'
        },
        {
            id: 5,
            percentage: "40%",
            body: 'Unveiling Ye Wreck details.'
        },
        {
            id: 6,
            percentage: "50%",
            body: 'Beginning planning and development of our DAO.'
        },
        {
            id: 7,
            percentage: "60%",
            body: 'Shiver AMA '
        }, 
        {
            id: 8,
            percentage: "70%",
            body: 'Reinvestments into marketing, Ye Wreck, new projects guided by the Shiver.  '
        },
        {
            id: 9,
            percentage: "80%",
            body: 'Treasure tokens are released for select holders and initiate staking.. '
        },
        {
            id: 10,
            percentage: "90%",
            body: ' DAO choice on Mutant Sharks artist selection.'
        },
        {
            id: 11,
            percentage: "95%",
            body: ' Minting of Mutant Sharks. Happy Shark holders will receive a Mutant Shark 1-for-1. '
        },
        {
            id: 12,
            percentage: "100%",
            body: 'Ye Wreck is fully operational, DAO is fully operational, Sea Treasures is fully operational.'
        },
        {
            id: 13,
            percentage: "100+%",
            body: 'Roadmap 2.0 Introducing Happy Sharks’ active utility Up to four times a year, up to a hundred Shark holders will have access to an exclusive IRL experience voted upon by the Shiver.'
        },
    ];

    return (

        <section className="roadmap space-top-bottom" id="roadmap">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="road-map-bg">
                            <div className="owl-outer-div">
                            
                                <div className='heading-roadmap'>
                                    <h3>The Roadmap</h3>
                                </div>
                                <OwlCarousel className='owl-theme' {...option}>

                                    {slidesData.map((slide) =>

                                        <>
                                            <div className="item" key={slide.id}>
                                                <div className="slick-slide-image">{slide.percentage}</div>
                                                <p>{slide.body}</p>
                                            </div>

                                        </>

                                    )}
                                </OwlCarousel>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Roadmap;
