import React from "react";
import logo from "../assets/site-logo.png";

function Header() {

  return (
    <section className="silder-section p-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="site-logo-outer">
              <a href="javascript:;"> <img className="site-logo img-fluid" alt="logo" src={logo} /></a>
              <h1>Take the plunge.</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Header;
