import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Accordion } from 'react-bootstrap';

function FaqAccordion() {

    return (
        <>
            <div className='id-space' id="faq"></div>
            <section className="accordion-section bg-black">
                <div className="container">
                    <div className="row">

                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header> How much does a Happy Shark cost to mint?</Accordion.Header>
                                <Accordion.Body>
                                    0.05 Ether.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>How many Happy Sharks can I mint?</Accordion.Header>
                                <Accordion.Body>
                                    A maximum of 3 Happy Sharks can be minted per transaction. You can, however, execute as many transactions as you like.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2">
                                <Accordion.Header> What blockchain is this on?</Accordion.Header>
                                <Accordion.Body>
                                    This collection of 10,000 Happy Sharks are ERC-721 tokens on the Ethereum blockchain and will be available to freely trade on OpenSea post mint.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3">
                                <Accordion.Header>  Do you charge an OpenSea commission?</Accordion.Header>
                                <Accordion.Body>
                                    {/* We charge a 2.5% commission on OpenSea and OpenSea charges a 2.5% commission on all sales that go to them. Therefore, it adds up to a 5% commission in total. Please see our roadmap for more information on where our commissions will go. */}

                                    We charge 5% commission + the 2.5% OpenSea for a total of 7.5% commission.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="4">
                                <Accordion.Header>    When is the official drop date?</Accordion.Header>
                                <Accordion.Body>
                                    {/* Minting goes live on Tuesday September 7th at 2:00 PM EST. */}
                                    Minting goes live on Late March through Late April.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="5">
                                <Accordion.Header>  Will there ever be any giveaways?</Accordion.Header>
                                <Accordion.Body>
                                    Absolutely! We have many smaller giveaways planned with prizes consisting of various NFTs and partial crypto coins, to bigger prizes consisting of giving winning real shark teeth and possibly the chance to go cage diving with real sharks!
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="6">
                                <Accordion.Header>  What if I still have a question that wasn’t covered?</Accordion.Header>
                                <Accordion.Body>
                                    Swim on over to our Discord, don’t worry the sharks don’t bite. We have a very active community and we’d be happy to answer any questions you may have.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </section>
        </>
    )
}
export default FaqAccordion;