import React,{useEffect} from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function Ourteam(){


    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return(
        <>
        <Navbar/>
        <div className="team-outer" id="ourteam">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="team-content space-top-bottom">
                            <h1>Meet Our Founder</h1>
                            <p>When it comes to collecting rare treasures, Mark2MarkIt is the biggest shark of them all. He’s been doing so since 1995 and doesn’t plan on stopping anytime soon. He’s swimming in crypto expertise and is diving deep into the current NFT frenzy.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}
export default Ourteam;