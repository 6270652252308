import React, { useState, useEffect } from 'react';

const Taketheplunge = ({ mint }) => {
    const [value, setValue] = useState(0);
    return (

        <section className="take-the-plunge ">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="take-text">
                            <h2>Take the plunge.</h2>
                            {/* <a href="javascript:;" className="yellow-custom-button"
                              onClick={() => {
                                mint(value);
                              }}
                            >mint a shark</a> */}
                            <a href="javascript:;" className="yellow-custom-button">mint a shark</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Taketheplunge;