import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

let theme = createTheme({
  palette: {
    primary: {
      main: "#111F2E",
    },
    background: {
      default: "#262626",
    },
    text: {
      primary: "rgba(0, 0 ,0 , 0.87)",
      secondary: "rgba(255, 255, 255, 0.87)",
    },
  },
  typography: {
    fontFamily: "Luckiest Guy, cursive",
    body1: {
      fontFamily: "Lato, sans-serif",
    },
    button: {
      fontFamily: 'Oswald, sans-serif',
    }
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          minHeight: "100vh",
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
// const useStyles = makeStyles(theme => ({
//   root: {
//     margin: theme.spacing(3),
//     width: 345,
//   },
//   media: {
//     height: 140,
//   },
//   title: {
//     color: theme.palette.primary.main
//   }
// }))

// background: rgb(33,147,176);
// background: linear-gradient(0deg, rgba(33,147,176,1) 0%, rgba(109,213,237,1) 100%);

// background-color: #045de9;
// background-image: linear-gradient(315deg, #045de9 0%, #09c6f9 74%);
// #1E1E1E
