import React from "react";
import logo from "../assets/site-logo.png";
import discord from "../assets/discord.png";
import opensea from "../assets/opensea.png";

function Footer() {

  return (
    <footer className="footer-section bg-black">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="footer-logo">
              <div className="site-logo-outer">
                <a href="javascript:;"> <img className="site-logo img-fluid" alt="logo" src={logo} /></a>
                <p>Contact Us</p>
              </div>
              <div className="social-icons">
                <ul>
                  {/* <li><a href="javascript:;"><i className='fa fa-facebook'></i></a></li> */}
                  <li><a href="https://discord.com/channels/876330240807419934/888978095581855804"  target="_blank"><img src={discord}/></a></li>
                  <li><a href="https://twitter.com/happysharksnft" target="_blank"><i className='fa fa-twitter'></i></a></li>
                  <li><a href="https://www.instagram.com/happysharksnft/" target="_blank" ><i className='fa fa-instagram'></i></a></li>
                  <li><a href="https://opensea.io/collection/happysharks"  target="_blank"><img src={opensea}/></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer;
